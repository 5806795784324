import Vue from 'vue'
import VueRouter from 'vue-router'
import { storeToRefs } from "pinia";
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
import Callback from '../views/auth/Callback.vue'
import Unauthorized from '../views/auth/Unauthorized.vue'
import authService from '../services/authservice';
import DataService from "../services/DataService";
import common from '../assets/common/vue-common.js'
Vue.use(VueRouter)
const routes = [
    {
        path: '/logout',
        name: 'logout',
        component: Unauthorized
    },
    {
        path: "/User/Home",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ '../components/Dashboard/Dashboard.vue'),
        meta: { title: 'Dashboard' }
    },
    {
        path: "/CRM/dashboard",
        name: "Dashboard",
        component: () => import(/* webpackChunkName: "Dashboard" */ '../views/CRM/Dashboard/Dashboard.vue'),
        meta: { title: 'Dashboard' }
    },
    {
        path: '/callback',
        name: 'callback',
        component: Callback
    },
    {
        path: '/logout',
        name: 'logout',
        component: Unauthorized
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },
    {
        path: "/Dashboard",
        name: "UserDashboard",
        component: () => import(/* webpackChunkName: "UserDashboard" */ '../components/Dashboard/Dashboard.vue'),
        meta: { title: 'Dashboard' }
    },
    {
        path: "/CRM/Index",
        name: "CRMLead",
        component: () => import(/* webpackChunkName: "CRMLead" */ '../views/CRM/Lead/List.vue'),
        meta: {
            title: 'Leads',
            breadcrumb: [
                { name: "Lead" }
            ]
        }
    },
    {
        path: "/CRM/ManageLeadAdd/:id/:isEdit/:moduleName/:subModuleCode",
        name: "CRMManageLeadAdd",
        component: () => import(/* webpackChunkName: "CRMManageLead" */ '../views/CRM/Lead/Manage.vue'),
        meta: {
            title: 'Manage Lead',
            breadcrumb: [
                { name: "Lead", link: "/CRM/Index" },
                { name: "Manage" }
            ],
            controllerName:'CRM',
            actionName: 'LeadDetails'
        }
    },
    {
        path: "/CRM/DealListing",
        name: "CRMDeal",
        component: () => import(/* webpackChunkName: "CRMDeal" */ '../views/CRM/Deal/List.vue'),
        meta: {
            title: 'Deals',
            breadcrumb: [
                { name: "Deal" }
            ]
        }
    },
    {
        path: "/CRM/ManageDeal/:id/:isEdit/:moduleName/:subModuleCode",
        name: "CRMManageDeal",
        component: () => import(/* webpackChunkName: "CRMManageDeal" */ '../views/CRM/Deal/Manage.vue'),
        meta: {
            title: 'Manage Deal',
            breadcrumb: [
                { name: "Deal", link: "/CRM/DealListing" },
                { name: "AddDeal" }
            ],
            actionName: 'ManageDeal'
        }
    },
    {
        path: "/CRM/ManageLeadEdit/:id/:isEdit/:moduleName/:subModuleCode",
        name: "CRMManageLeadEdit",
        component: () => import(/* webpackChunkName: "CRMManageLead" */ '../views/CRM/Lead/Manage.vue'),
        meta: {
            title: 'Manage Lead',
            breadcrumb: [
                { name: "Lead", link: "/CRM/Index" },
                { name: "Manage" }
            ],
            controllerName:'CRM',
            actionName: 'LeadDetailsEdit'
        }
    },
    {
        path: "/CRM/ViewLeadDetails/:id/:moduleName/:subModuleCode",
        name: "CRMLeadDetails",
        component: () => import(/* webpackChunkName: "CRMLeadDetails" */ '../views/CRM/Lead/ViewLeadDetails.vue'),
        meta: {
            title: 'View Lead Details',
            breadcrumb: [
                { name: "Lead", link: "/CRM/Index" },
                { name: "Details" }
            ],
            actionName: 'ViewLeadDetails'
        }
    },
    {
        path: "/CRM/ViewDealDetails/:id/:moduleName/:subModuleCode",
        name: "CRMDealDetails",
        component: () => import(/* webpackChunkName: "CRMDealDetails" */ '../views/CRM/Deal/ViewDealDetails.vue'),
        meta: {
            title: 'View Deal Details',
            breadcrumb: [
                { name: "Deal", link: "/CRM/DealListing" },
                { name: 'Details' }
            ],
            actionName: 'ViewDealDetails'
        }
    },
    {
        path: "/CRM/Accounts",
        name: "CRMAccounts",
        component: () => import(/* webpackChunkName: "CRMAccounts" */ '../views/CRM/Accounts/AccountsList.vue'),
        meta: {
            title: 'Accounts',
            breadcrumb: [{ name: "Accounts" }]
        }
    },
    {
        path: "/CRM/ManageAccount",
        name: "CRMManageAccount",           
        component: () => import(/* webpackChunkName: "CRMManageAccount" */ "../views/CRM/Accounts/Manage.vue"),
        meta: { title: 'Manage Account',userguide:'UserGuideAddAccountInformation',
        breadcrumb:[
        {name:"Account", link:"/CRM/Accounts"},
        {name: "Manage"}
    ]} 
    },
    {
        path: "/CRM/ManageCampaign/:id/:isEdit/:moduleName/:subModuleCode",
        name: "ManageCampaign",    
        component: () => import(/* webpackChunkName: "ManageCampaign" */ "../views/CRM/Campaign/ManageCampaign.vue"),
        meta: { title: 'Manage Campaign',
        breadcrumb: [
            { name: "Campaign", link: "/CRM/CampaignListing"},
            { name: "Manage" }
        ],
    actionName:'ManageCampaign'  }
    },

    {
        path: "/Brand/BrandListing",
        name: "CRMBrand",
        component: () => import(/* webpackChunkName: "CRMBrand" */ '../views/CRM/Brand/List.vue'),
        meta: {
            title: 'BrandListing',
            breadcrumb: [
                { name: "Brand" }
            ],
            controllerName:'CRM',
            actionName: 'BrandListing'
        }
    },
    {
        path: "/Brand/SocialConnect",
        name: "SocialConnect",
        component: () => import(/* webpackChunkName: "SocialConnect" */ "../views/CRM/Brand/SocialConnect.vue"),
        meta: { title: 'Brand',
        breadcrumb: [
            {name:"Brand", link:"/Brand/BrandListing"}, 
            {name: "Social Connect"} 
        ]} 
    },
    {
        path: "/CRM/ImportContacts/:reqfrom",
        name: "CRMImportContacts",
        component: () => import(/* webpackChunkName: "CRMImportContacts" */ "../components/Common/ImportContacts.vue"),
        meta: { title: 'Import Contacts',
        breadcrumb:[
            {name:"Import Contacts", link:"/CRM/ContactListing"},
            {name: "Microsoft Excel"}
        ]} 
    },
    {
        path: "/CRM/CampaignListing",
        name: "CRMCampaign",
        component: () => import(/* webpackChunkName: "CRMCampaign" */ '../views/CRM/Campaign/CampaignListing.vue'),
        meta: {
            title: 'Campaign',
            breadcrumb: [
                { name: "Campaign" }
            ]
        }
    },
    {
        path: "/CommunicationModes/Index",
        name: "CommunicationmodeList",
        component: () => import(/* webpackChunkName: "CommunicationmodeList" */ '../views/CRM/CommunicationModes/List.vue'),
        meta: {
            title: 'CommunicationModesList',
            breadcrumb: [{ name: "Communicationmode" }]
        }
    },
    {
        path: "/CRM/ManageContact/:id/:moduleName/:subModuleCode",
        name: "CRMManageContact",        
        component: () => import(/* webpackChunkName: "CRMManageContact" */ "../views/CRM/Contacts/Manage.vue"),
        meta: { title: 'Manage Contact',
        breadcrumb:[
            {name:"Contact", link:"/CRM/ContactListing"},
            {name: "Manage"}
        ],
    actionName:'ManageContact'} 
    },
    {
        path: "/CRM/ManageCommunicationModes/:id/:isEdit",
        name: "ManageCommunication",           
        component: () => import(/* webpackChunkName: "ManageCommunication" */ "../views/CRM/CommunicationModes/ManageCommunicationModes.vue"),
        meta: { title: 'Manage CommunicationModes',
     breadcrumb:[
        {name: "Communication Modes", link:"/CommunicationModes/Index"},
        {name: "Manage"}
     ]} 
    },
    {
        path: "/CRM/ContactListing",
        name: "ContactListing",
        component: () => import(/* webpackChunkName: "ContactListing" */ '../views/CRM/Contacts/List.vue'),
        meta: {
            title: 'ContactListing',
            breadcrumb: [
                { name: "Contact" }
            ]
        }
    },
    {
        path: "/CRM/ContractListing",
        name: "Contract",
        component: () => import(/* webpackChunkName: "Contract" */ '../views/CRM/Contract/ContractListing.vue'),
        meta: {
            title: 'Contract',
            breadcrumb: [
                { name: "Contract" }
            ]
        }
    },
    {
        path: "/CRM/ManageContract/:id/:moduleName/:subModuleCode",
        name: "CRMManageContract",      
        component: () => import(/* webpackChunkName: "CRMManageContract" */ "../views/CRM/Contract/Manage.vue"),
        meta: { title: 'Manage Contract',
        breadcrumb:[
            {name:"Contract", link:"/CRM/ContractListing"},
            {name: "AddContract"}
        ],
      actionName:'ManageContract'} 
    },
    {
        path: "/CRM/InvoiceListing",
        name: "CRM Invoice",
        component: () => import(/* webpackChunkName: "CRM Invoice" */ '../views/CRM/Invoice/InvoiceListing.vue'),
        meta: {
            title: 'Invoice List',
            breadcrumb: [
                { name: "Invoice" }
            ]
        }
    },
    {
        path: "/CRM/ManageInvoice/:id/:moduleName/:subModuleCode",
        name: "ManageInvoice",
        component: () => import(/* webpackChunkName: "ManageInvoice" */ "../views/CRM/Invoice/ManageInvoice.vue"),
        meta: { title: 'Manage Invoice' , 
        breadcrumb: [
            { name: "CRM Invoice",link:"/CRM/InvoiceListing" },
            { name: "Manage Invoice" }
        ],
    actionName:'ManageInvoice' } 
        },
    {
        path: "/CRM/InwardListing",
        name: "InwardList",
        component: () => import(/* webpackChunkName: "InwardList" */ "../views/CRM/InWard/List.vue"),
        meta: {
            title: 'Goods Inward',
            breadcrumb: [
                { name: "Goods Inward" }
            ]
        }
    },
    {
        path: "/CRM/Manageinward/:id/:isEdit/:moduleName/:subModuleCode",
        name: "Manage Manageinward",           
        component: () => import(/* webpackChunkName: "Manage Manageinward" */ "../views/CRM/InWard/Manage.vue"),
        meta: { title: 'Goods Inward',
     breadcrumb:[
        {name: "Goods Inward", link:"/CRM/InwardListing"},
        {name: "Manage"}
     ],
    actionName:'ManageInward'} 
    },
    {
        path: "/CRM/PriceBookListing",
        name: "CRMPriceBook",
        component: () => import(/* webpackChunkName: "CRMPriceBook" */ '../views/CRM/PriceBook/List.vue'),
        meta: {
            title: 'Price Book Listing',
            breadcrumb: [
                { name: "Price Book" }
            ]
        }
    },
    {
        path: "/CRM/ItemListing",
        name: "Product",
        component: () => import(/* webpackChunkName: "Product" */ '../views/CRM/Product/List.vue'),
        meta: {
            title: 'Product',
            breadcrumb: [
                { name: "Product" }
            ]
        }
    },
    {
        path: "/CRM/PurchaseOrderListing",
        name: "CRMPurchaseOrder",
        component: () => import(/* webpackChunkName: "CRMPurchaseOrder" */ '../views/CRM/PurchaseOrder/List.vue'),
        meta: {
            title: 'PurchaseOrder',
            breadcrumb: [
                { name: "PurchaseOrder" }
            ]
        }
    },
    {
        path: "/CRM/ManageProductAdd/:id/:reqFromId",
        name: "CRMManageProductAdd",           
        component: () => import(/* webpackChunkName: "CRMManageProduct" */ "../views/CRM/Product/Manage.vue"),
        meta: { title: 'Manage Product' ,
        breadcrumb:[
        {name:"Product", link:"/CRM/ItemListing"},
        {name: "Manage"}
        ],
        controllerName:'AssetCatalog',
        actionName: 'Add'
    } 
    },
    {
        path: "/CRM/ManageProductEdit/:id/:reqFromId",
        name: "CRMManageProductEdit",           
        component: () => import(/* webpackChunkName: "CRMManageProduct" */ "../views/CRM/Product/Manage.vue"),
        meta: { title: 'Manage Product' ,
        breadcrumb:[
        {name:"Product", link:"/CRM/ItemListing"},
        {name: "Manage"}
        ],
        controllerName:'AssetCatalog',
        actionName: 'Update'
    } 
    },
    {
        path: "/CRM/QuotationListing",
        name: "CRMQuote",
        component: () => import(/* webpackChunkName: "CRMQuote" */ '../views/CRM/Quote/List.vue'),
        meta: {
            title: 'Quote Listing',
            breadcrumb: [
                { name: "Quotes" }
            ]
        }
    },
    {
        path: "/CRM/SalesOrderListing",
        name: "CRMSalesOrderListing",

        component: () => import(/* webpackChunkName: "CRMSalesOrderListing" */ '../views/CRM/SalesOrder/SalesOrderList.vue'),
        meta: {
            title: 'Sales Order',
            breadcrumb: [
                { name: "Sales Order" }
            ]
        }
    },
    {
        path: "/CRM/ManageSalesOrder/:id/:moduleName/:subModuleCode/:reqFromId",
        name: "CRMManageSalesOrder",
     component: () => import(/* webpackChunkName: "CRMManageSalesOrder" */ "../views/CRM/SalesOrder/Manage.vue"),
        meta: { title: 'Manage SalesOrder',
        breadcrumb:[
            {name:"SalesOrder", link:"/CRM/SalesOrderListing"},
            {name: "Manage"}
        ],
    actionName:'ManageSalesOrder'}
    },
    {
        path: "/CRM/StageListing",
        name: "CRMStage",
        component: () => import(/* webpackChunkName: "CRMStage" */ '../views/CRM/Stage/List.vue'),
        meta: {
            title: 'Stage Listing',
            breadcrumb: [
                { name: "Stage" }
            ]
        }
    },
    {
        path: "/CRM/ManageStage/:id/:moduleName/:subModuleCode",
        name: "CRMManageStage",
       component: () => import(/* webpackChunkName: "CRMManageStage" */ "../views/CRM/Stage/Manage.vue"),
        meta: { title: 'Manage Stage',
        breadcrumb:[
            {name:"Stage", link:"/CRM/StageListing"},
            {name: "Manage"}
        ],
    actionName:'ManageStage'} 
    },
    {
        path: "/CRM/VendorListing",
        name: "CRMVendor",
        component: () => import(/* webpackChunkName: "CRMVendor" */ '../views/CRM/Vendor/List.vue'),
        meta: {
            title: 'Vendor Listing',
            breadcrumb: [
                { name: "Vendor" }
            ]
        }
    },
    {
        path: "/CRMReport/Index",
        name: "CRMReport",
        component: () => import(/* webpackChunkName: "CRMReport" */ '../views/CRM/Reports/Index.vue'),
        meta: {
            title: 'CRM Report',
            breadcrumb: [{ name: "CRM Report" }]
        }
    },
    {
        path: "/CRM/ViewAccountDetails/:id/:moduleName/:subModuleCode/:AccountCode",
        name: "Accounts Details",
        component: () => import(/* webpackChunkName: "Accounts Details" */ "../views/CRM/Accounts/ViewAccountDetails.vue"),
        meta: {
            title: 'Accounts Details',
            breadcrumb: [
                { name: "Account", link: "/CRM/Accounts" },
                { name: "Details" }
            ]
        }
    },
    {
        path: "/CRM/ViewCampaignDetails/:id/:moduleName/:subModuleCode",
        name: "Campaign Details",
        component: () => import(/* webpackChunkName: "Campaign Details" */ "../views/CRM/Campaign/ViewCampaignDetails.vue"),
        meta: {
            title: 'Campaign Details',
            breadcrumb: [
                { name: "Campaign", link: "/CRM/CampaignListing" },
                { name: 'Details' }
            ],
            actionName: 'ViewCampaignDetails'
        }
    },
    {
        path: "/CRM/ViewContactDetails/:id/:moduleName/:subModuleCode",
        name: "CRMContactDetails",
        component: () => import(/* webpackChunkName: "CRMContactDetails" */ "../views/CRM/Contacts/ViewContactDetails.vue"),
        meta: {
            title: 'View Contact Details',
            breadcrumb: [
                { name: "Contact", link: "/CRM/ContactListing" },
                { name: "Details" }
            ],
            actionName: 'ViewContactDetails'
        }
    },
    {
        path: "/CRM/ViewContractDetails/:id/:moduleName/:subModuleCode",
        name: "View Contract Details",
        component: () => import(/* webpackChunkName: "View Contract Details" */ "../views/CRM/Contract/ViewContractDetails.vue"),
        meta: {
            title: 'View Contract Details',
            breadcrumb: [
                { name: "Contract", link: "/CRM/ContractListing" },
                { name: "Details" }
            ],
            actionName: 'ViewContractDetails'
        }
    },
    {
        path: "/CRM/ViewInvoiceDetails/:id/:moduleName/:subModuleCode",
        name: "View Invoice Details",
        component: () => import(/* webpackChunkName: "View Invoice Details" */ "../views/CRM/Invoice/ViewInvoiceDetails.vue"),
        meta: {
            title: 'View Invoice Details',
            breadcrumb: [
                { name: "Invoice", link: "/CRM/InvoiceListing" },
                { name: "Details" }
            ],
            actionName: 'ViewInvoiceDetails'
        }
    },
    {
        path: "/CRM/ViewQuotationDetails/:id/:moduleName/:subModuleCode",
        name: "ViewQuotationDetails",
        component: () => import(/* webpackChunkName: "ViewQuotationDetails" */ '../views/CRM/Quote/ViewQuotationDetails.vue'),
        meta: {
            title: 'View Quotation Details',
            breadcrumb: [
                { name: "Quotes", link: "/CRM/QuotationListing" },
                { name: "Details" }
            ],
            actionName: 'ViewQuotationDetails'
        }
    },
    {
        path: "/CRM/ViewSalesOrderDetails/:id/:moduleName/:subModuleCode",
        name: "SalesOrderDetails",
        component: () => import(/* webpackChunkName: "SalesOrderDetails" */ '../views/CRM/SalesOrder/SalesOrderDetails.vue'),
        meta: {
            title: 'Sales Order Details',
            breadcrumb: [
                { name: "Sales Order", link: "/CRM/SalesOrderListing" },
                { name: "Details" }
            ],
            actionName: 'ViewSalesOrderDetails'
        }
    },
    {
        path: "/CRM/ViewStageDetails/:id/:moduleName/:subModuleCode",
        name: "ViewStageDetails",
        component: () => import(/* webpackChunkName: "ViewStageDetails" */ '../views/CRM/Stage/ViewStageDetails.vue'),
        meta: {
            title: 'View Stage Details',
            breadcrumb: [
                { name: "Stage", link: "/CRM/StageListing" },
                { name: "Details" }
            ],
            actionName: 'ViewStageDetails'
        }
    },
    {
        path: "/CRM/ViewVendorDetails/:id/:moduleName/:subModuleCode",
        name: "ViewVendorDetails",
        component: () => import(/* webpackChunkName: "ViewVendorDetails" */ '../views/CRM/Vendor/ViewVendorDetails.vue'),
        meta: {
            title: 'View Vendor Details',
            breadcrumb: [
                { name: "Vendor", link: "/CRM/VendorListing" },
                { name: "Details" }
            ],
            actionName: 'ViewVendorDetails'
        }
    },
    {
        path: "/CRM/ViewInwardDetails/:id/:moduleName/:subModuleCode",
        name: "Inward Details",
        component: () => import(/* webpackChunkName: "Inward Details" */ "../views/CRM/InWard/ViewInwardDetails.vue"),
        meta: {
            title: 'View Inward Details',
            breadcrumb: [
                { name: "Goods Inward", link: "/CRM/InwardListing" },
                { name: "Details" }
            ],
            actionName: 'ViewInwardDetails'
        }
    },
    {
        path: "/CRM/ViewPriceBookDetails/:id/:moduleName/:subModuleCode",
        name: "View PriceBook Details",
        component: () => import(/* webpackChunkName: "View PriceBook Details" */ "../views/CRM/PriceBook/ViewPriceBookDetails.vue"),
        meta: {
            title: 'View PriceBook Details',
            breadcrumb: [
                { name: "Price Book", link: "/CRM/pricebooklisting" },
                { name: "Details" }
            ],
            actionName: 'ViewPriceBookDetails'
        }
    },
    {
        path: "/CRM/ManagePriceBook/:id/:moduleName/:subModuleCode",
        name: "CRMManagePriceBook",      
        component: () => import(/* webpackChunkName: "CRMManagePriceBook" */ "../views/CRM/PriceBook/Manage.vue"),
        meta: { title: 'Manage PriceBook',
        breadcrumb:[
            {name:"Price Book", link:"/CRM/PriceBookListing"},
            {name: "Manage"}
        ],
    actionName:'ManagePriceBook'} 
    },
    {
        path: "/CRM/ViewItemDetails/:id/:moduleName/:subModuleCode",
        name: "Details Product",
        component: () => import(/* webpackChunkName: "Details Product" */ "../views/CRM/Product/ViewItemDetails.vue"),
        meta: {
            title: 'Details Product',
            breadcrumb: [
                { name: "Product", link: "/CRM/ItemListing" },
                { name: "Details" }
            ]
        }
    },
    {
        path: "/CRM/ViewPurchaseOrderDetails/:id/:moduleName/:subModuleCode",
        name: "ViewPurchaseOrderDetails",
        component: () => import(/* webpackChunkName: "ViewPurchaseOrderDetails" */ "../views/CRM/PurchaseOrder/ViewPurchaseOrderDetails.vue"),
        meta: {
            title: 'View Purchase Order Details',
            breadcrumb: [
                { name: "Ref. PurchaseOrder", link: "/CRM/PurchaseOrderListing" },
                { name: "Details" }
            ],
            actionName: 'ViewPurchaseOrderDetails'
        }
    },
    {
        path: "/CRM/ManagePurchaseOrder/:id/:moduleName/:subModuleCode",
        name: "CRMManagePurchaseOrder",     
        component: () => import(/* webpackChunkName: "CRMManagePurchaseOrder" */ "../views/CRM/PurchaseOrder/Manage.vue"),
        meta: { title: 'Manage PurchaseOrder',
        breadcrumb: [
            { name: "Ref. PurchaseOrder", link: "/CRM/PurchaseOrderListing"},
            { name: "Manage" }
        ],
    actionName:'ManagePurchaseOrder'  } 
    },
    {
        path: "/CRM/ManageVendor/:id/:isEdit/:moduleName/:subModuleCode",
        name: "CRMManageVendor",    
        component: () => import(/* webpackChunkName: "CRMManageVendor" */ "../views/CRM/Vendor/Manage.vue"),
        meta: { title: 'Manage Vendor',
        breadcrumb:[
            {name:"Vendor", link:"/CRM/VendorListing"},
            {name: "Manage"}
        ],
    actionName:'ManageVendor'} 
    },
    {
        path: "/CRM/ManageQuotation/:id/:moduleName/:subModuleCode/:reqFromId",
        name: "ManageQuotation",
        component: () => import(/* webpackChunkName: "ManageQuotation" */ "../views/CRM/Quote/Manage.vue"),
        meta: {
            title: 'Manage Quotation',
            breadcrumb: [
                { name: "Quotes", link: "/CRM/QuotationListing" },
                { name: "Manage" }
            ],
            actionName: 'ManageQuotation'
        }
    },
    {
        path: "/CRM/ImportLeads",
        name: "CRMImportLeads",
        component: () => import(/* webpackChunkName: "CRMImportLeads" */ "../components/Common/ImportLeads.vue"),
        meta: { title: 'Import Leads Detail',
        breadcrumb: [                 
            {name: "Import Leads"} 
        ]}
    },
    {
        path: "/CRM/LeadImportDetails/:subModuleCode/:batchid",
        name: "CRMLeadImportDetails",
        component: () => import(/* webpackChunkName: "CRMLeadImportDetails" */ "../components/Common/LeadImportDetails.vue"),
        meta: { title: 'Lead Import Details',
        breadcrumb: [
            {name:"Import Leads", link:"/CRM/ImportLeads"}, 
            {name: "Import Lead Details"} 
        ]} 
    },
    {
        path: "/CRM/ContactImportDetails/:subModuleCode/:reqfrom/:batchid",
        name: "CRMContactImportDetails",
        component: () => import(/* webpackChunkName: "CRMContactImportDetails" */ "../components/Common/ContactImportDetails.vue"),
        meta: { title: 'Import Contacts details',
        breadcrumb:[
            {name:"Contact", link:"/CRM/ContactListing"},
            {name: "Import Contacts details"}
        ]} 
    },

    {
        path: "/CRM/ImportAccounts",
        name: "CRMImportAccounts",
        component: () => import(/* webpackChunkName: "CRMImportAccounts" */ "../components/Common/ImportAccounts.vue"),
        meta: { title: 'Import Accounts Detail',
        breadcrumb: [   
            {name: "Accounts",link:"/CRM/Accounts"},             
            {name: "Import Accounts"} 
        ]} 
    },
    {
        path: "/CRM/ItemTypeListing",
        name: "ProductType",
        component: () => import(/* webpackChunkName: "Product" */ '../views/CRM/ProductType/List.vue'),
        meta: {
            title: 'Product Type',
            breadcrumb: [
                { name: "Product Type" }
            ]
        }
    },
    {
        path: "/CRM/ViewItemTypeDetail/:id/:moduleName/:subModuleCode",
        name: "ProductTypeDetail",
        component: () => import(/* webpackChunkName: "Product Type Detail" */ '../views/CRM/ProductType/ViewItemTypeDetail.vue'),
        meta: {
            title: 'Product Type Detail',
            breadcrumb: [
                { name: "Product Type Detail" }
            ],
            controllerName:'CRM',
            actionName:'ViewItemTypeDetails'
        }
    },

]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
}) 
const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {
 
    document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
    document.getElementById("divProgress1").style.display = 'none';
    document.getElementById("talygenfooter").style.display = 'block';
    if (to.path == '/callback' || to.path == '/unauthorized' || to.path == '/logoutcallback') {
        next()
    }
    else {
        if (to.path == '/logout') {
            authService.logOut();
        } else {
            if(to.path.indexOf('ContactImportDetails')>-1 && to.path.indexOf('CRM_ACCOUNTS')>-1){
              to.meta.breadcrumb=[{name:"Accounts", link:"/CRM/Accounts"},
              {name: "Import Account details"}]
            }else if(to.path.indexOf('ContactImportDetails')>-1 && to.path.indexOf('CRM_CONTACTS')>-1){
                to.meta.breadcrumb=[{name:"Contact", link:"/CRM/ContactListing"},
                {name: "Import Contacts details"}]
            }
            const ustore = useloggedUserInfo();
            let app = ustore.GetUserInfo;
            if (app) {
                let isCheckPrivilege = true;
                if(to.meta.hasOwnProperty('checkPrivilege'))
                {
                    isCheckPrivilege = to.meta.checkPrivilege;
                }
                if(isCheckPrivilege) {
                    let controllerName = "", actionName = "";
                    if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                        controllerName = to.meta.controllerName;
                        actionName = to.meta.actionName;
                    }
                    else{
                        let pathArray = to.path.split("/");
                        if(pathArray.length > 2){
                            controllerName = pathArray[1];
                            actionName = pathArray[2];
                        } else{
                            controllerName = pathArray[0];
                            actionName = pathArray[1];
                        }
                    }
                    if(controllerName != "" && actionName != "") {
                        DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                            if (value.data)
                                next()
                            else
                                //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                                next('/unauthorized');
                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                    } else {
                        next()
                    }
                } else {
                    next()
                }
            }
            else 
            {
               

                authService.authenticate(to.path).then(() => {


                    console.log('authenticating a protected url:' + to.path);
                    next();
                });
            }
        }
    }
});
export default router

